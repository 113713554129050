* + .more-escape-game--light {
  padding-top: 90px !important;
  padding-bottom: 50px;
}

@media (--mobile) {
  * + .more-escape-game--light {
    padding-top: 64px !important;
    padding-bottom: 32px;
  }
}

* + .mission-gallery {
  margin-top: 112px;
}
.mission-description + .mission-gallery {
  margin-top: 200px;
}
@media (--mobile) {
  .mission-description + .mission-gallery {
    margin-top: 100px;
  }
}

.mission-gallery + .more-escape-game--light {
  margin-top: 200px;
}

@media (--mobile) {
  .mission-gallery + .more-escape-game--light {
    margin-top: 100px;
  }
}

.mission-gallery {
  position: relative;
}

.mission-gallery__left-illustration {
  position: absolute;
  left: -300px;
  bottom: -30px;
  width: 385px;
  z-index: 2;
}

.mission-gallery__right-illustration {
  position: absolute;
  right: -160px;
  bottom: -30px;
  width: 335px;
}

.mission-gallery__left-illustration,
.mission-gallery__right-illustration {
  transform: translateY(50px) scale(0.8);
}

@media (--mobile) {
  .mission-gallery__left-illustration,
  .mission-gallery__right-illustration {
    transform: scale(0.5);
  }

  .mission-gallery__left-illustration {
    top: 100px;
    left: -200px;
  }

  .mission-gallery__right-illustration {
    top: 110px;
    transform: scaleX(-1) scale(0.5);
  }
}

.mission-gallery--use-characters {
  padding-bottom: 100px;
}

.mission-gallery--use-gradient::before {
  content: '';

  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  pointer-events: none;

  width: 100%;
  height: 1200px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 73%, #040814 100%),
    radial-gradient(
      60% 60% at 50% 50%,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  transform: translate(-50%, -50%);
}
@media (--mobile) {
  .mission-gallery--use-gradient::before {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 73%, #040814 100%),
      radial-gradient(
        60% 60% at 50% 50%,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0) 80%
      );
  }
}

.mission-gallery__container {
  position: relative;
}

.mission-gallery__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
}

.mission-gallery__content {
  /* center the content */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* set the height of the container */
  height: 100%;
  /* set the width of the container */
  width: 100%;
}

* + .mission-gallery__gallerie__container {
  margin-top: 32px;
}

.mission-gallery__gallerie {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 16px;
  height: 540px;
  grid-template-areas:
    'one one one one two two two three three three three three'
    'one one one one four four four four five five five five';
}
.mission-gallery__gallerie--text-index-3 {
  grid-template-areas:
    'one one one one two two two two three three three three'
    'one one one one four four four four four five five five';
}

.mission-gallery__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  width: auto;
  overflow: hidden;
  border-radius: 5px;
}

.mission-gallery__item__content__mobile {
  display: none;
}

.mission-gallery__item:nth-child(1) {
  grid-area: one;
}
.mission-gallery__item:nth-child(2) {
  grid-area: two;
}
.mission-gallery__item:nth-child(3) {
  grid-area: three;
}
.mission-gallery__item:nth-child(4) {
  grid-area: four;
}
.mission-gallery__item:nth-child(5) {
  grid-area: five;
}

.mission-gallery__item__stars {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.mission-gallery__item__image {
  height: 100%;
  width: 100%;

  background-color: var(--dark);
}

.mission-gallery__item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

* + .mission-gallery__item__content__testimony {
  margin-top: 16px;
}

.mission-gallery__item__content__testimony {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}

@media (--mobile) {
  .mission-gallery__item__content__testimony {
    max-width: 230px !important;
  }
}

@media (--tablet) {
  .mission-gallery__item__content__testimony {
    max-width: 400px;
  }
}

* + .mission-gallery__item__content__author {
  margin-top: 16px;
}

.mission-gallery__item__content__author {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;

  text-align: center;
  letter-spacing: 0.02em;

  color: rgba(255, 255, 255, 0.9);
}

.mission-gallery__gallerie__container__button-block {
  text-align: center;
}

@media (--mobile) {
  .mission-gallery__gallerie__container {
    width: 100%;
    height: auto;
  }

  .mission-gallery__gallerie {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 12px;
    height: 400px;
    grid-template-areas:
      'one two '
      'one three ';
  }

  * + .mission-gallery__gallerie__container-characters {
    margin-top: 260px;
  }

  .mission-gallery__content {
    align-items: center;
  }

  .mission-gallery__item__content__mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
  }

  .mission-gallery__item:nth-child(1) {
    grid-area: one;
  }
  .mission-gallery__item:nth-child(2) {
    grid-area: two;
    height: 200px;
  }
  .mission-gallery__item:nth-child(3) {
    grid-area: unset;
    display: none;
  }
  .mission-gallery__item:nth-child(4) {
    grid-area: three;
    height: 200px;
  }
  .mission-gallery__item:nth-child(5) {
    display: none;
    grid-area: unset;
  }

  .mission-gallery__gallerie__container__button-block {
    margin-top: 32px;
  }
}

/* @media (--mobile) {
  .mission-gallery__gallerie {
    grid-template-columns: 1fr;
    grid-template-rows: unset;
    grid-template-areas: unset;
    height: unset;
    max-width: 100%;
  }

  .mission-gallery__item {
    grid-area: unset !important;
  }

  .mission-gallery__item img {
    height: 200px;
  }

  .mission-gallery__item:nth-child(1) {
    order: 2;
    padding-top: 250px;
  }
  .mission-gallery__item:nth-child(2) {
    order: 3;
  }
  .mission-gallery__item:nth-child(3) {
    order: 1;
  }
  .mission-gallery__item:nth-child(4) {
    order: 4;
  }
  .mission-gallery__item:nth-child(5) {
    order: 5;
  }

  .mission-gallery--use-characters {
    padding-bottom: 0;
  }
} */
