.slider {
  --slide-transition: 0.8s;
  --slide-width: 594px;
  --offset: 48px;

  background-color: var(--white);
  padding: 64px 0;
  position: relative;
  overflow: hidden;
}
@media (--mobile) {
  .slider {
    padding: 32px 0;
  }
}

.slider__background {
  position: absolute;
  top: 0;
  right: 0;

  width: 100%;
  height: 100%;
}

.slider__background__overlay {
  position: absolute;
  top: 0;
  right: 0;

  width: 100%;
  height: 100%;

  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
}

.slider__background__image {
  position: absolute;
  top: 0;
  right: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider__transition-left {
  position: absolute;
  top: 0;
  left: 0;

  z-index: 0;
}

.slider__transition-right {
  position: absolute;
  top: 0;
  right: 0;

  z-index: 1;
}

@media screen and (max-width: 1335px) {
  .slider__transition-left {
    display: none;
  }

  .slider__transition-right {
    display: none;
  }
}

.slider__desktop {
  display: flex;
  min-height: 448px;
  height: 100%;
  position: relative;
  gap: 48px;
}
.slider__mobile {
  display: none;
}
@media (--tablet) {
  .slider__desktop {
    display: none;
  }
  .slider__mobile {
    display: flex;
    min-height: 448px;
    height: 100%;
    position: relative;
    gap: 48px;
  }
}
@media (--mobile) {
  .slider__mobile {
    display: block;
  }
}

.slider__title {
  text-align: center;
}
.slider__title h2 {
  -webkit-text-stroke-width: 0.3px;
  -webkit-text-stroke-color: black;
}
.slider--custom-background .slider__title h2 {
  color: white;
  -webkit-text-stroke-width: 0;
}

@media (--tablet) {
  .slider__title h2 {
    color: var(--dark);
    -webkit-text-stroke-width: 0;
    -webkit-text-stroke-color: transparent;
    font-weight: 400;
  }
  .slider__title h2 {
    color: white;
  }
}

@media (--mobile) {
  .slider__title {
    margin-top: 16px;
    margin-bottom: 32px;
  }
  .slider__title h2 {
    color: var(--dark);
    -webkit-text-stroke-width: 0;
    -webkit-text-stroke-color: transparent;
    font-weight: 400;
  }
}

.slider__slide__images,
.slider__slide__content {
  flex: 1;
}

.slider__slide__images {
  position: relative;
  padding: 22px 0;
}

.slider__slide__images:after {
  content: '';
  position: absolute;
  top: 0;
  right: -50px;
  width: 50px;
  height: 100%;

  pointer-events: none;

  background-image: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0,
    transparent
  );
  z-index: 1;
}
.slider--custom-background .slider__slide__images:after {
  background: none;
}

.slider__slide__images__wrap {
  position: absolute;
  top: 20%;
  right: 50px;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.slider__slide__images__container {
  position: relative;
  width: 227px;
  height: 287px;
  overflow: hidden;
  border-radius: 5px;
  transition: all 200ms ease-in-out;

  transform: scale(1);
  margin-right: 24px;
}

.slider__slide__images__container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background: rgba(255, 255, 255, 1);
  transition: all 200ms ease-in-out;

  opacity: 0;
}

.slider__slide__images__container--inactive::before {
  opacity: 0.5;
}

.slider__slide__images__container--active {
  box-shadow: -16px 1px 48px rgba(4, 8, 20, 0.2);
  transition: all 200ms ease-in-out;

  transform: scale(1.44);
}

.slider__slide__images__container:first-of-type.slider__slide__images__container--active {
  transition: all 200ms ease-in-out;
  margin-left: 46px;
  margin-right: 0;
}

.slider__slide__images__container.slider__slide__images__container--active {
  transition: all 200ms ease-in-out;
  margin-left: 46px;
  margin-right: 70px;
}

.slider__slide__images__container:last-of-type.slider__slide__images__container--active {
  transition: all 200ms ease-in-out;

  margin-right: 70px;
}
.slider__slide__images__container--inactive {
  transition: all 200ms ease-in-out;
}

.slider__slide__images__container--passed {
  transition: all 200ms ease-in-out;
  margin-right: 24px;
  opacity: 0;
}

.slider__slide__images__container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider__slide__content {
  position: relative;
  width: var(--slide-width);
  overflow: hidden;
}

.slider__slide__model {
  width: 9000vw;
  display: flex;
  opacity: 0;
}

.slider__slide__model__slide,
.slider__slide__content__container__wrap {
  width: var(--slide-width);
  background-color: white;
  padding: 42px var(--offset) 90px var(--offset);
  scroll-snap-align: center;
}

.slider__slide__content__container {
  position: absolute;
  user-select: none;
  max-width: 594px;
  width: 100%;
  height: 100%;
  overflow: auto;
  top: 0;
}

@media (--tablet) {
  .slider {
    --slide-width: 490px;
  }

  .slider__desktop {
    flex-direction: column-reverse;
  }

  .slider__slide__images {
    padding-bottom: 405px;
  }

  .slider__slide__images__wrap {
    right: 15%;
  }

  .slider__slide__content__container__wrap,
  .slider__slide__content {
    width: 100vw;
  }

  .slider__slide__content__container {
    max-width: 100vw;
  }

  .slider__slide__model__slide {
    width: 70vw;
  }

  .slider__content__buttons {
    bottom: 0;
  }
}

.slider__slide__content__container::-webkit-scrollbar {
  display: none;
}

.slider__slide__content__wrap {
  scroll-snap-type: x mandatory;
  display: flex;
  position: absolute;
  overflow: visible;
  height: 100%;
  top: 0;
}

.hide-arrow-outline {
  opacity: 0;
  pointer-events: none;
  cursor: default;
}

.slider__slide__content__container .heading__title > * {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;

  color: var(--dark);
}

.mobile-slider .title-title > * {
  color: var(--dark);
}

.slider__slide__content__container .heading__title strong {
  color: var(--violet-500);
  font-weight: 400;
}

.slider__slide__content__container .ruler {
  border-top: 7px solid var(--violet-500);
}

* + .slider__slide__content__container__description {
  margin-top: 16px;
}

.slider__slide__content__container__description p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.02em;

  color: var(--dark);
}

* + .slider__slide__content__container__description p,
* + .slider__slide__content__container__description strong {
  margin-top: 16px;
}

.slider__slide__content__container__description strong {
  font-style: normal;
  color: var(--violet-500);
  font-weight: 400;
}

* + .slider__slide__content__container__button {
  margin-top: 16px;
}

.slider__content__buttons {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: auto;
  z-index: 1;

  bottom: 0;
  left: 48px;
}

@media (--tablet) {
  .slider__content__buttons {
    right: 6%;
    top: 1px;
  }
}

@media (--mobile) {
  .slider__content__buttons {
    right: 12%;
  }
}

.slider__content__buttons__arrow-right {
  margin-left: 8px;
}

.slider__slide__content__container__wrap--green .ruler {
  border-top: 7px solid var(--green-500);
}

.slider__slide__content__container__wrap--green .heading__title strong {
  color: var(--green-500);
}

.slider__slide__content__container__wrap--green strong {
  color: var(--green-500);
}

.slider__slide__content__container__wrap--raspberry .ruler {
  border-top: 7px solid var(--raspberry-500);
}

.slider__slide__content__container__wrap--raspberry .heading__title strong {
  color: var(--raspberry-500);
}

.slider__slide__content__container__wrap--raspberry strong {
  color: var(--raspberry-500);
}

.slider__slide__content__container__wrap--yellow .ruler {
  border-top: 7px solid var(--yellow-500);
}

.slider__slide__content__container__wrap--yellow .heading__title strong {
  color: var(--yellow-500);
}

.slider__slide__content__container__wrap--yellow strong {
  color: var(--yellow-500);
}

.slider__slide__content__container__wrap--blue .ruler {
  border-top: 7px solid var(--blue-500);
}

.slider__slide__content__container__wrap--blue .heading__title strong {
  color: var(--blue-500);
}

.slider__slide__content__container__wrap--blue strong {
  color: var(--blue-500);
}

.mobile-slider .title-title h2 strong {
  font-weight: 400;
}
