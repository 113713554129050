.booking {
  padding: 60px 0 70px 0;
  background-color: var(--white);
}
* + .booking {
  margin-top: 85px;
}
* + .booking--reservation-page {
  margin-top: 0;
}
@media (--mobile) {
  * + .booking {
    margin-top: 0;
  }
  .booking {
    padding: 20px 0;
  }
}

.booking__title {
  text-align: center;
  font-size: 32px;
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  text-transform: uppercase;
  color: black;
  font-weight: 400;
}
@media (--mobile) {
  .booking__title {
    margin-top: 32px;

    font-size: 26px;
  }
  .booking--reservation-page .booking__title {
    margin-top: 16px;
    font-size: 24px;
  }
}

.booking__embed {
  position: relative;
  z-index: 1;
  min-height: 650px;
}
* + .booking__embed {
  margin-top: 32px;
}

.booking__footer-notice {
  color: black;
  line-height: 1.3;
}
* + .booking__footer-notice {
  margin-top: 24px;
}

.booking__footer-notice * + p {
  margin-top: 1em;
}
