.button {
  --height: 50px;
  --min-height: var(--height);
  --horizontal-padding: 24px;
  --vertical-padding: 15px;
  --color: var(--blue-900);
  --background-color: var(--blue-500);

  position: relative;

  display: inline-block;
  height: var(--height);
  min-height: var(--min-height);
  padding-right: var(--horizontal-padding);
  padding-left: var(--horizontal-padding);
  overflow: hidden;

  text-decoration: none;

  outline: none;
  cursor: pointer;
  user-select: none;
  transition-duration: 200ms;
  transition-property: transform;
}
.button--theme-primary-violet,
.button--theme-primary-raspberry,
.button--theme-primary-green {
  --color: var(--white);
}
@media (--mobile) {
  .button {
    --height: 46px;
  }
}

.button--disabled {
  --background-color: lightgrey !important;

  cursor: default;
}

/** Heights **/

.button--height-mobile-cta {
  --height: 40px;
  --horizontal-padding: 20px;
  --vertical-padding: 7px;
}

.button--height-small {
  --height: 35px;
  --vertical-padding: 7px;
  --horizontal-padding: 16px;
}

.button--height-extra-small {
  --height: auto;
  --min-height: 32px;
  --vertical-padding: 4px;
}

/** Horizontal paddings **/

.button--horizontal-padding-large {
  --horizontal-padding: 42px;
}

/** Themes **/
.button--theme-violet {
  --horizontal-padding: 50px;

  --color: var(--white);
  --background-color: var(--violet-500);
}

.button--theme-green {
  --horizontal-padding: 50px;

  --color: var(--white);
  --background-color: var(--green-500);
}

.button--theme-raspberry {
  --horizontal-padding: 50px;

  --color: var(--white);
  --background-color: var(--raspberry-500);
}

.button--theme-yellow {
  --horizontal-padding: 15px;

  --color: var(--dark);
  --background-color: var(--yellow-500);
}

.button--theme-secondary {
  --color: var(--primary-600);
  --background-color: var(--secondary-500);
}

.button--theme-light {
  --height: auto;
  --color: var(--dark);
  --background-color: none;
  --horizontal-padding: 0;
}

.button--theme-transparent {
  --color: var(--white);
  --background-color: transparent;
  padding-right: 0;
  padding-left: 0;
}

.button--theme-transparent-blue {
  --color: var(--blue-500);
  --background-color: transparent;
  padding-right: 0;
  padding-left: 0;
}

.button--theme-dark {
  --color: var(--white);
  --background-color: var(--dark);
}

.button--theme-border-black {
  --horizontal-padding: 15px;
  border: 1px solid var(--primary-500);
}

.button--theme-border-black .button__background {
  background-color: rgba(255, 255, 255, 0);
}

.button--theme-border-black .button__text {
  color: var(--primary-500);
}

.button--theme-border-white {
  --horizontal-padding: 15px;
  border: 1px solid var(--white);
}

.button--theme-border-white .button__background {
  background-color: rgba(255, 255, 255, 0);
}

.button--theme-border-white .button__text {
  color: var(--white);
}

.button--disabled.button--theme-light {
  --background-color: none !important;
}

.button--theme-outline {
  --color: var(--white);
  --background-color: rgba(255, 255, 255, 0.04);
}

.button--theme-outline-violet {
  --color: var(--violet-500);
  --background-color: rgba(255, 255, 255, 0.04);
}
.button--theme-outline-green {
  --color: var(--green-500);
  --background-color: rgba(255, 255, 255, 0.04);
}
.button--theme-outline-raspberry {
  --color: var(--raspberry-500);
  --background-color: rgba(255, 255, 255, 0.04);
}
.button--theme-outline-blue {
  --color: var(--blue-500);
  --background-color: rgba(255, 255, 255, 0.04);
}
.button--theme-outline-yellow {
  --color: var(--yellow-500);
  --background-color: rgba(255, 255, 255, 0.04);
}
.button--theme-outline-black {
  --color: var(--black);
  --background-color: rgba(255, 255, 255, 0.04);
}

.button--theme-text {
  --color: var(--white);
  --background-color: transparent;
}
.button--theme-outline-white-violet {
  --color: var(--white);
  --background-color: rgba(255, 255, 255, 0.04);
}

.button--theme-outline-white-raspberry {
  --color: var(--white);
  --background-color: rgba(255, 255, 255, 0.04);
}

.button--theme-outline-white-green {
  --color: var(--white);
  --background-color: rgba(255, 255, 255, 0.04);
}

/** Hover state **/

.button:not(.button--disabled):not(.button--theme-transparent):hover {
  transform: scale(1.05);
}
.button:not(.button--disabled):not(.button--theme-transparent):active {
  transform: scale(1);
  transition-duration: 100ms;
  transition-property: transform;
}

.button--theme-outline:not(.button--disabled):hover {
  --background-color: rgba(255, 255, 255, 0.1);
}

.button--theme-primary-white:not(.button--disabled):hover {
  --color: var(--primary-600);
  --background-color: rgba(240, 245, 255, 1);
}

.button--theme-violet:not(.button--disabled):active {
  --background-color: var(--violet-500);
}

.button--theme-green:not(.button--disabled):hover {
  --background-color: var(--green-500);
}

.button--theme-raspberry:not(.button--disabled):hover {
  --background-color: var(--raspberry-500);
}

.button--theme-secondary:not(.button--disabled):hover {
  --background-color: var(--white);
}

.button--theme-secondary-outline:not(.button--disabled):hover {
  --color: var(--secondary-400);
  --background-color: rgba(216, 189, 115, 0.1);
}

.button--theme-transparent:not(.button--disabled):hover {
  --color: var(--white);
  --background-color: transparent;
}

.button--theme-outline:not(.button--disabled):hover {
  --color: var(--blue-500);
}

.button--theme-outline-white-violet:not(.button--disabled):hover {
  --color: var(--violet-500);
}

.button--theme-outline-white-raspberry:not(.button--disabled):hover {
  --color: var(--raspberry-500);
}

.button--theme-outline-white-green:not(.button--disabled):hover {
  --color: var(--green-500);
}

.button--theme-outline-violet:not(.button--disabled):hover {
  --color: var(--blue-500);

  --background-color: rgba(255, 255, 255, 0.1);
}
.button--theme-outline-green:not(.button--disabled):hover {
  --color: var(--blue-500);

  --background-color: rgba(255, 255, 255, 0.1);
}
.button--theme-outline-raspberry:not(.button--disabled):hover {
  --color: var(--blue-500);

  --background-color: rgba(255, 255, 255, 0.1);
}

.button--theme-outline-blue:not(.button--disabled):hover {
  --color: var(--blue-500);

  --background-color: rgba(255, 255, 255, 0.1);
}

.button--theme-outline-yellow:not(.button--disabled):hover {
  --color: var(--yellow-500);

  --background-color: rgba(255, 255, 255, 0.1);
}
.button--theme-outline-black:not(.button--disabled):hover {
  --color: var(--black);

  --background-color: rgba(255, 255, 255, 0.1);
}

.button.button--theme-text:not(.button--disabled):hover {
  --color: var(--white);

  --background-color: transparent;
}

.button.button--theme-outline-orange:not(.button--disabled):hover {
  --color: var(--orange-500);
  --background-color: rgba(223, 162, 68, 0.1);
}

.button.button--theme-blue:not(.button--disabled):hover {
  --color: var(--white);
  --background-color: rgb(20, 53, 92);
}

.button--theme-light:not(.button--disabled):hover {
  --color: var(--white);
  --background-color: none;
}

/** Active state **/

.button:not(.button--disabled):active {
  --background-color: var(--blue-500);
}

.button--theme-outline:not(.button--disabled):active {
  --color: var(--blue-500);
  --background-color: rgba(255, 255, 255, 0.04);
}

.button--theme-outline-violet:not(.button--disabled):active {
  --color: var(--blue-500);
  --background-color: rgba(255, 255, 255, 0.04);
}
.button--theme-outline-green:not(.button--disabled):active {
  --color: var(--blue-500);
  --background-color: rgba(255, 255, 255, 0.04);
}
.button--theme-outline-raspberry:not(.button--disabled):active {
  --color: var(--blue-500);
  --background-color: rgba(255, 255, 255, 0.04);
}

.button--theme-outline-blue:not(.button--disabled):active {
  --color: var(--blue-500);
  --background-color: rgba(255, 255, 255, 0.04);
}

.button--theme-outline-yellow:not(.button--disabled):active {
  --color: var(--yellow-500);
  --background-color: rgba(255, 255, 255, 0.04);
}
.button--theme-outline-black:not(.button--disabled):active {
  --color: var(--black);
  --background-color: rgba(255, 255, 255, 0.04);
}
.button--theme-outline-white-violet:not(.button--disabled):active {
  --color: var(--violet-500);
  --background-color: rgba(255, 255, 255, 0.04);
}

.button--theme-outline-white-raspberry:not(.button--disabled):active {
  --color: var(--raspberry-500);
  --background-color: rgba(255, 255, 255, 0.04);
}

.button--theme-outline-white-green:not(.button--disabled):active {
  --color: var(--green-500);
  --background-color: rgba(255, 255, 255, 0.04);
}

.button.button--theme-outline-orange:not(.button--disabled):active {
  --color: var(--orange-500);
  --background-color: rgba(223, 162, 68, 0.3);
}

.button--theme-primary-white:not(.button--disabled):active {
  --color: var(--primary-600);
  --background-color: rgba(229, 235, 247, 1);
}

.button.button--theme-blue:not(.button--disabled):active {
  --color: var(--white);
  --background-color: var(--primary-500);
}

.button--theme-yellow:not(.button--disabled):active {
  --color: var(--dark);
  --background-color: var(--yellow-500);
}

.button--theme-secondary-outline:not(.button--disabled):active {
  --color: var(--secondary-500);
  --background-color: none;
}

.button--theme-light:not(.button--disabled):active {
  --color: var(--white);
  --background-color: none;
}

.button--theme-secondary:not(.button--disabled):active {
  --color: var(--primary-600);
  --background-color: var(--secondary-500);
}

.button--theme-transparent:not(.button--disabled):active {
  --color: var(--white);
  --background-color: transparent;
}

/** Background **/

.button__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: var(--background-color);
  transition-duration: 200ms;
  transition-property: opacity, background-color;
}

.button--theme-outline .button__background {
  border: 1px solid var(--white);
}

.button--theme-primary-violet .button__background {
  background-color: var(--violet-500);
}

.button--theme-primary-raspberry .button__background {
  background-color: var(--raspberry-500);
}

.button--theme-primary-green .button__background {
  background-color: var(--green-500);
}

.button--theme-outline-violet .button__background {
  border: 1px solid var(--violet-500);
}
.button--theme-outline-green .button__background {
  border: 1px solid var(--green-500);
}
.button--theme-outline-raspberry .button__background {
  border: 1px solid var(--raspberry-500);
}
.button--theme-outline-blue .button__background {
  border: 1px solid var(--blue-500);
}
.button--theme-outline-yellow .button__background {
  border: 1px solid var(--yellow-500);
}
.button--theme-outline-black .button__background {
  border: 1px solid var(--black);
}

.button--theme-outline-white-violet .button__background {
  border: 1px solid var(--white);
}

.button--theme-outline-white-raspberry .button__background {
  border: 1px solid var(--white);
}

.button--theme-outline-white-green .button__background {
  border: 1px solid var(--white);
}

.button--theme-outline-white-violet:hover .button__background {
  border: 1px solid var(--violet-500);
}

.button--theme-outline-white-raspberry:hover .button__background {
  border: 1px solid var(--raspberry-500);
}

.button--theme-outline-white-green:hover .button__background {
  border: 1px solid var(--green-500);
}

.button--theme-outline:hover .button__background {
  border: 1px solid var(--blue-500);
}

.button--theme-outline-violet:hover .button__background {
  border: 1px solid var(--violet-500);
  background-color: rgba(89, 50, 138, 0.05);
}
.button--theme-outline-green:hover .button__background {
  border: 1px solid var(--green-500);
  background-color: rgba(74, 137, 62, 0.05);
}
.button--theme-outline-raspberry:hover .button__background {
  border: 1px solid var(--raspberry-500);
  background-color: rgba(211, 16, 78, 0.05);
}

.button--theme-outline-blue:hover .button__background {
  border: 1px solid var(--blue-500);
  background-color: rgba(20, 53, 92, 0.05);
}

.button--theme-outline-yellow:hover .button__background {
  border: 1px solid var(--yellow-500);
  background-color: rgba(255, 203, 18, 0.05);
}
.button--theme-outline-black:hover .button__background {
  border: 1px solid var(--black);
  background-color: rgba(0, 0, 0, 0.05);
}

.button--theme-primary-violet:hover .button__background {
  background-color: rgba(255, 255, 255, 0.04);
  border: 1px solid var(--violet-500);
}

.button--theme-primary-raspberry:hover .button__background {
  background-color: rgba(255, 255, 255, 0.04);
  border: 1px solid var(--raspberry-500);
}

.button--theme-primary-green:hover .button__background {
  background-color: rgba(255, 255, 255, 0.04);
  border: 1px solid var(--green-500);
}

.button--theme-violet:not(.button--disabled):hover .button__background {
  background-color: var(--violet-600);
  border: 1px solid var(--violet-500);
}

.button--theme-raspberry:not(.button--disabled):hover .button__background {
  background-color: var(--raspberry-600);
  border: 1px solid var(--raspberry-500);
}

.button--theme-green:not(.button--disabled):hover .button__background {
  background-color: var(--green-600);
  border: 1px solid var(--green-500);
}

.button--theme-violet:not(.button--disabled):active .button__background {
  background-color: var(--violet-500);
  border: 1px solid var(--violet-500);
}

.button--theme-raspberry:not(.button--disabled):active .button__background {
  background-color: var(--raspberry-500);
  border: 1px solid var(--raspberry-500);
}

.button--theme-green:not(.button--disabled):active .button__background {
  background-color: var(--green-500);
  border: 1px solid var(--green-500);
}

.button--theme-primary .button__background {
  border: 1px solid var(--blue-500);
}

/** Content **/

.button__content {
  display: inline-flex;
  align-items: center;
  height: var(--height);
  min-height: var(--min-height);
}

.button__left-icon {
  position: relative;
  display: flex;
  align-items: center;
}
.button__left-icon svg {
  --size: 16px;

  width: var(--size);
  height: var(--size);
  object-fit: contain;
}

.button__text {
  z-index: 1;
  position: relative;

  flex: 1;
  display: flex;
  align-items: center;
  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);

  white-space: nowrap;
  color: var(--color);
  text-decoration: none;
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5;
  text-transform: uppercase;

  text-align: center;
}
@media (--mobile) {
  .button__text {
    font-size: 14px;
  }
}
.button--height-mobile-cta .button__text {
  font-size: 16px;
}

.button--theme-outline-violet:hover .button__text {
  color: var(--violet-500);
}
.button--theme-outline-green:hover .button__text {
  color: var(--green-500);
}
.button--theme-outline-raspberry:hover .button__text {
  color: var(--raspberry-500);
}

.button--theme-primary-violet:hover .button__text {
  color: var(--violet-500);
}

.button--theme-primary-raspberry:hover .button__text {
  color: var(--raspberry-500);
}

.button--theme-primary-green:hover .button__text {
  color: var(--green-500);
}

.button--theme-outline-blue:hover .button__text {
  color: var(--blue-500);
}

.button--theme-outline-yellow:hover .button__text {
  color: var(--yellow-500);
}
.button--theme-outline-black:hover .button__text {
  color: var(--black);
}

.button--theme-violet .button__text,
.button--theme-green .button__text,
.button--theme-raspberry .button__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
}

.button.button--theme-light .button__text {
  font-family: var(--font-family);
  font-weight: normal;
  text-transform: none;
}

.button.button--theme-light-blue .button__text {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
}

.button.button--theme-light-white .button__text {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
}

.button.button--theme-blue .button__text {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
}

.button.button--theme-transparent .button__text {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  text-transform: none;
}

.button.button--theme-transparent-blue .button__text {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  text-transform: none;
}

.button.button--theme-transparent:not(.button--disabled):hover .button__text {
  text-decoration: underline;
}

.button.button--theme-text .button__text {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  text-transform: none;
}

* + .button__text {
  margin-left: 10px;
}

.button__right-icon {
  position: relative;

  display: flex;
  align-items: center;
  height: 20px;
  padding-left: 16px;
}

.button--theme-simple .button__right-icon {
  padding-left: 0;
}

.button--theme-blue .button__right-icon svg * {
  fill: var(--white);
}

.button .button__right-icon svg * {
  fill: var(--white);
}

.button--theme-simple .button__right-icon svg * {
  fill: var(--color);
}

.button--theme-blue .button__left-icon svg * {
  fill: var(--white);
}

.button--theme-light:hover .button__right-icon svg * {
  fill: var(--secondary-500);
}

.button--theme-light .button__right-icon svg * {
  fill: var(--dark);
}

.button--theme-secondary-outline .button__right-icon svg * {
  fill: var(--secondary-500);
}
.button--theme-outline .button__right-icon svg * {
  fill: var(--white);
}

.button--theme-transparent .button__right-icon svg circle {
  fill: var(--blue-500);
}

.button--theme-transparent-blue .button__right-icon svg * {
  fill: var(--blue-500);
}

.button--theme-text .button__right-icon svg * {
  fill: none;
  stroke: var(--white);
}

.button--theme-text .button__right-icon svg {
  --size: 30px;
}

.button--theme-light * + .button__right-icon {
  margin-left: 0;
}

.button--theme-light-blue * + .button__right-icon {
  margin-left: 0;
}

.button--theme-light-white * + .button__right-icon {
  margin-left: 0;
}

.button__right-icon svg {
  --size: 14px;

  width: var(--size);
  height: var(--size);
  object-fit: contain;
}
